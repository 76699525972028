import * as Sentry from "@sentry/browser";
import Axios from "axios";

import {
  ForgotPasswordRequest,
  LoginRequest,
  MyThunkResult,
  RegisterRequest,
  ResetPasswordRequest,
  VerifyEmailRequest,
  VerifyEmailResponse,
  VerifyResetPasswordTokenRequest,
  UserContext,
} from "src/features/auth/types";
import { formatApiError } from "src/helpers";

function setSentryData(userContext: UserContext) {
  const userId = userContext.currentUser?.id;
  const emailAddress = userContext.currentUser?.emailAddress;
  const organizationId = userContext.currentOrganization?.id;

  Sentry.configureScope((scope) => {
    if (userId && emailAddress) {
      scope.setUser({ id: userId.toString(), email: emailAddress });
    } else {
      scope.setUser(null);
    }

    scope.setExtra("organization_id", organizationId);
  });
}

export const updateUserContext = (): MyThunkResult<UserContext> => {
  return async (dispatch) => {
    dispatch({ request: null, type: "UPDATE_USER_CONTEXT_REQUEST" });

    const response = await Axios.get<UserContext>("/api/user-context");
    setSentryData(response.data);
    dispatch({ request: null, response: response.data, type: "UPDATE_USER_CONTEXT_RESPONSE" });
    return Promise.resolve(response.data);
  };
};

export const updateUserContextWithoutLoading = (): MyThunkResult<UserContext> => {
  return async (dispatch) => {
    //dispatch({ request: null, type: "UPDATE_USER_CONTEXT_REQUEST" });

    const response = await Axios.get<UserContext>("/api/user-context");
    setSentryData(response.data);
    dispatch({ request: null, response: response.data, type: "UPDATE_USER_CONTEXT_RESPONSE" });
    return Promise.resolve(response.data);
  };
};

export const login = (request: LoginRequest): MyThunkResult<UserContext> => {
  return async (dispatch) => {
    dispatch({ request, type: "LOGIN_REQUEST" });

    try {
      const response = await Axios.post<UserContext>("/api/auth/login", request);
      setSentryData(response.data);
      dispatch({
        request,
        response: response.data,
        type: "LOGIN_SUCCESS",
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(formatApiError(error));
    }
  };
};

export const logout = (): MyThunkResult<void> => {
  return (dispatch) => {
    return Axios.post("/api/auth/logout").then(() => {
      setSentryData({ isLoggedIn: false });
      dispatch({
        request: null,
        type: "LOGOUT_REQUEST",
      });
    });
  };
};

export const register = (request: RegisterRequest): MyThunkResult<void> => {
  return async (dispatch) => {
    dispatch({ request, type: "REGISTER_REQUEST" });

    try {
      const response = await Axios.post("/api/account/register", request);
      dispatch({
        request,
        response: response.data,
        type: "REGISTER_SUCCESS",
      });
      return Promise.resolve();
    } catch (error) {
      dispatch({
        error: formatApiError(error),
        request,
        type: "REGISTER_ERROR",
      });
      return Promise.reject(formatApiError(error));
    }
  };
};

export const forgotPassword = (request: ForgotPasswordRequest): MyThunkResult<void> => {
  return async (dispatch) => {
    dispatch({ request, type: "FORGOT_PASSWORD_REQUEST" });

    try {
      const response = await Axios.post("/api/account/forgot-password", request);
      dispatch({
        request,
        response: response.data,
        type: "FORGOT_PASSWORD_SUCCESS",
      });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(formatApiError(error));
    }
  };
};

export const verifyResetPasswordToken = (request: VerifyResetPasswordTokenRequest): MyThunkResult<void> => {
  return async (dispatch) => {
    dispatch({ request, type: "VERIFY_RESET_PASSWORD_TOKEN_REQUEST" });

    try {
      const response = await Axios.post("/api/account/verify-reset-password-token", request);
      dispatch({
        request,
        response: response.data,
        type: "VERIFY_RESET_PASSWORD_TOKEN_SUCCESS",
      });
      return Promise.resolve();
    } catch (error) {
      dispatch({
        error: formatApiError(error),
        request,
        type: "VERIFY_RESET_PASSWORD_TOKEN_ERROR",
      });
      return Promise.reject(formatApiError(error));
    }
  };
};

export const resetPassword = (request: ResetPasswordRequest): MyThunkResult<void> => {
  return async (dispatch) => {
    dispatch({ request, type: "RESET_PASSWORD_REQUEST" });

    try {
      const response = await Axios.post("/api/account/reset-password", request);
      dispatch({
        request,
        response: response.data,
        type: "RESET_PASSWORD_SUCCESS",
      });
      return Promise.resolve();
    } catch (error) {
      dispatch({
        error: formatApiError(error),
        request,
        type: "RESET_PASSWORD_ERROR",
      });
      return Promise.reject(formatApiError(error));
    }
  };
};

export const verifyEmail = (request: VerifyEmailRequest): MyThunkResult<VerifyEmailResponse> => {
  return async (dispatch) => {
    dispatch({ request, type: "VERIFY_EMAIL_REQUEST" });

    try {
      const response = await Axios.post<VerifyEmailResponse>("/api/account/verify-email", request);
      dispatch({
        request,
        response: response.data,
        type: "VERIFY_EMAIL_SUCCESS",
      });
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch({
        error: formatApiError(error),
        request,
        type: "VERIFY_EMAIL_ERROR",
      });
      return Promise.reject(formatApiError(error));
    }
  };
};
