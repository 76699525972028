// eslint-disable-next-line import/order
import { hot } from "react-hot-loader/root"; // This must be imported before any React imports.

import * as Sentry from "@sentry/browser";
import Axios from "axios";
import React from "react";
import { render } from "react-dom";
import { toast, Slide } from "react-toastify";

import App from "src/App";

import "src/i18n";

import "../scss/styles.scss";
import "react-toastify/dist/ReactToastify.css";

Sentry.init({ dsn: "https://e78dda2fa6d1404983055ccc5176de1e@o287450.ingest.sentry.io/5205542" });

Axios.defaults.xsrfHeaderName = "X-XSRF-Token";
Axios.defaults.xsrfCookieName = "mapovate_xsrf_token_client";
Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

toast.configure({
  newestOnTop: true,
  transition: Slide,
});

const HotApp = hot(App);

render(<HotApp />, document.getElementById("root"));
