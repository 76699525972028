import * as Sentry from "@sentry/browser";
import React from "react";

type Props = unknown;

type State = {
  error?: any;
  errorInfo?: any;
  eventId?: string;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // eslint-disable-next-line no-console
    //console.log({ error, errorInfo });

    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ error, errorInfo, eventId });
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="error-boundary-box">
          <h5>Something went wrong :(</h5>
          <p>{this.state.error && this.state.error.toString()}</p>
          <details style={{ whiteSpace: "pre-wrap" }}>
            <summary>More details</summary>
            {this.state.errorInfo.componentStack}
          </details>
          <button
            type="button"
            className="btn btn-sm btn-danger mt-4"
            onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
          >
            Report Feedback
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
