import Axios from "axios";
import { Formik, Form, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import AlertMessage from "src/components/AlertMessage";
import HtmlPhrase from "src/components/HtmlPhrase";
import SubmitButton from "src/components/SubmitButton";
import TextField from "src/components/TextField";
import { InviteResponse, LoginRequest, UserContext } from "src/features/auth/types";
import { ErrorInfo, handleModelStateErrors } from "src/helpers";
import { USER_EMAIL_ADDRESS_MAX_LENGTH } from "src/models";

type Props = {
  inviteToken: string;
} & InviteResponse;

export default function AcceptInviteLoginForm(props: Props) {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const initialValues: LoginRequest = { emailAddress: props.invite.emailAddress, password: "", rememberMe: true };

  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string()
      .trim()
      .email(t("Validation_Email_Invalid"))
      .required(t("Validation_Email_Required"))
      .max(USER_EMAIL_ADDRESS_MAX_LENGTH, t("Validation_EmailAddress_TooLong", { max: USER_EMAIL_ADDRESS_MAX_LENGTH })),
    password: Yup.string().required(t("Validation_Password_Required")).min(8, t("Validation_Password_Min8")),
  });

  const handleSubmit = (values: LoginRequest, helpers: FormikHelpers<LoginRequest>) => {
    helpers.setSubmitting(true);
    setError("");

    Axios.post<UserContext>(`/api/invitation/${props.invite.id}/${props.inviteToken}/login`, values)
      .then(() => {
        window.location.assign("/");
      })
      .catch((err: ErrorInfo) => {
        helpers.setSubmitting(false);
        if (!handleModelStateErrors(err, helpers.setErrors, setError)) {
          setError(t("UnknownError"));
        }
      });
  };

  return (
    <div>
      <h4 className="mb-4">{t("JoinOrganization")}</h4>
      <p className="alert alert-info">
        <HtmlPhrase phrase={t("JoinOrganization_LoginMessage", { name: props.organization.name })} />
      </p>
      <AlertMessage message={error} />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, isValid }) => (
          <Form>
            <TextField name="emailAddress" type="email" placeholder={t("EmailAddress")} autoComplete="email" disabled />
            <TextField name="password" type="password" placeholder={t("Password")} autoComplete="current-password" />
            <SubmitButton
              text={t("Login")}
              className="btn-lg btn-block mt-2"
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
