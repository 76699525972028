import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import ErrorBoundary from "src/components/ErrorBoundary";
import HtmlPhrase from "src/components/HtmlPhrase";
import SplashLayout from "src/features/app/SplashLayout";
import ForgotPasswordForm from "src/features/auth/ForgotPasswordForm";

type Props = RouteComponentProps;

export default function ForgotPasswordPage(props: Props) {
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = useState("");

  const handlePasswordResetSuccess = (emailAddress: string) => {
    setUserEmail(emailAddress);
  };

  if (userEmail) {
    return (
      <SplashLayout>
        <ErrorBoundary>
          <h4 className="mb-4">{t("ResetPassword_Title")}</h4>
          <HtmlPhrase phrase={t("ResetPassword_SuccessMessage")} component="div" />
        </ErrorBoundary>
      </SplashLayout>
    );
  }

  return (
    <SplashLayout>
      <h4 className="mb-4">{t("ResetPassword_Title")}</h4>
      <p>{t("ResetPassword_Message")}</p>
      <ErrorBoundary>
        <ForgotPasswordForm onSuccess={handlePasswordResetSuccess} {...props} />
      </ErrorBoundary>
    </SplashLayout>
  );
}
