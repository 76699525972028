import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import SplashLayout from "src/features/app/SplashLayout";
import { verifyEmail } from "src/features/auth/actions";
import { MyThunkDispatch } from "src/features/auth/types";

type RouteParams = {
  userId: string;
  token: string;
};

type Props = RouteComponentProps<RouteParams>;

const VerifyEmailPage = (props: Props) => {
  const userId = parseInt(props.match.params.userId, 10);
  const { token } = props.match.params;

  const { t } = useTranslation();
  const [success, setSuccess] = useState(true);
  const dispatch = useDispatch<MyThunkDispatch>();

  useEffect(() => {
    dispatch(verifyEmail({ resetPassword: false, token, userId }))
      .then((response) => {
        setSuccess(true);

        if (response.resetToken) {
          props.history.push(`/reset-password/${userId}/${response.resetToken}`);
        } else {
          window.location.assign("/");
        }
      })
      .catch(() => {
        setSuccess(false);
      });
  }, [userId, token, dispatch, props.history]);

  return (
    <SplashLayout>
      <h4 className="mb-4 text-center">{t("ValidatingEmail")}</h4>
      {!success && <div>{t("EmailValidationFailure")}</div>}
    </SplashLayout>
  );
};

VerifyEmailPage.displayName = "VerifyEmailPage";

export default VerifyEmailPage;
