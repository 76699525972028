import { useField } from "formik";
import React from "react";

type FieldType =
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "range"
  | "search"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

type Props = {
  name: string;
  type?: FieldType;
  id?: string;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  excludeDecoration?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextField = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const [field, meta] = useField<string>(props);
  const { name, type, id, label, helpText, excludeDecoration, className, ...rest } = props;

  let controlCssClass = "form-control";

  if (meta.touched) {
    if (meta.error) {
      controlCssClass += " is-invalid";
    } else {
      controlCssClass += " is-valid";
    }
  }

  if (className) {
    controlCssClass += ` ${className}`;
  }

  const fieldType = type || "text";

  const controlMarkup = (
    <>
      <input id={id || name} type={fieldType} ref={ref} className={controlCssClass} {...field} {...rest} />
      {helpText && <div className="form-text text-muted">{helpText}</div>}
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </>
  );

  if (excludeDecoration) {
    return controlMarkup;
  }

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={id || name} className="col-form-label">
          {label}
        </label>
      )}
      {controlMarkup}
    </div>
  );
});

TextField.defaultProps = {
  excludeDecoration: false,
};

TextField.displayName = "TextField";

export default TextField;
