import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import SplashLayout from "src/features/app/SplashLayout";

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <SplashLayout>
      <h2>{t("NotFound_Title")}</h2>
      <p>{t("NotFound_Message")}</p>
      <div>
        <Link to="/" className="btn btn-primary">
          {t("NotFound_CallToAction")}
        </Link>
      </div>
    </SplashLayout>
  );
}
