import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import AlertMessage from "src/components/AlertMessage";
import SubmitButton from "src/components/SubmitButton";
import TextField from "src/components/TextField";
import { ResetPasswordRequest } from "src/features/auth/types";

interface Props {
  userId: number;
  token: string;
  error: string;
  onSubmit(values: ResetPasswordRequest, helpers: FormikHelpers<ResetPasswordRequest>): void;
}

const ResetPasswordForm = (props: Props) => {
  const { t } = useTranslation();

  const initialValues: ResetPasswordRequest = {
    confirmPassword: "",
    password: "",
    token: props.token,
    userId: props.userId,
  };

  const validationSchema = Yup.object().shape({
    confirmPassword: Yup.string()
      .required(t("Validation_ConfirmPassword_Required"))
      .oneOf([Yup.ref("password")], t("Validation_ConfirmPassword_Mismatch")),
    password: Yup.string().required(t("Validation_Password_Required")).min(8, t("Validation_Password_Min8")),
  });

  return (
    <>
      <AlertMessage message={props.error} />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={props.onSubmit}>
        {({ isSubmitting, isValid }) => (
          <Form>
            <TextField name="password" type="password" placeholder={t("Password")} autoComplete="new-password" />
            <TextField
              name="confirmPassword"
              type="password"
              placeholder={t("RetypePassword")}
              autoComplete="new-password"
            />
            <SubmitButton
              text={t("ChangePassword")}
              className="btn-lg btn-block mt-2"
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

ResetPasswordForm.displayName = "ResetPasswordForm";

export default ResetPasswordForm;
