/* eslint-disable no-param-reassign */

import produce from "immer";

import { OrganizationActions, OrganizationsState } from "src/features/organizations/types";

const initialState: OrganizationsState = {
  isLoading: true,
  organizations: [],
};

export default (state: OrganizationsState = initialState, action: OrganizationActions) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "GET_ORGANIZATIONS_REQUEST": {
        draft.isLoading = true;
        return draft;
      }

      case "GET_ORGANIZATIONS_SUCCESS": {
        draft.isLoading = false;
        draft.organizations = action.response;
        return draft;
      }

      default: {
        return draft;
      }
    }
  });
};
