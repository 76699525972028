import Axios from "axios";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Switch, useHistory } from "react-router-dom";

import AppState from "src/AppState";
import Loading from "src/components/Loading";
import PrivateRoute from "src/components/PrivateRoute";
import Forbidden from "src/features/app/Forbidden";
import MainPage from "src/features/app/MainPage";
import NotFound from "src/features/app/NotFound";
import AcceptInvitationPage from "src/features/auth/AcceptInvitationPage";
import ForgotPasswordPage from "src/features/auth/ForgotPasswordPage";
import LoginPage from "src/features/auth/LoginPage";
import RegisterPage from "src/features/auth/RegisterPage";
import ResetPasswordPage from "src/features/auth/ResetPasswordPage";
import VerifyEmailPage from "src/features/auth/VerifyEmailPage";
import { updateUserContext } from "src/features/auth/actions";
import { MyThunkDispatch } from "src/features/auth/types";

import store from "src/store";

const App = () => {
  const { t, i18n } = useTranslation();
  const isLoading = useSelector<AppState, boolean>((state) => state.authState.isLoading);
  const dispatch = useDispatch<MyThunkDispatch>();
  const history = useHistory();

  useEffect(() => {
    Axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (Axios.isCancel(error)) {
          return Promise.reject(error);
        }

        if (error.response.status === 401) {
          window.location.assign("/");
          return null;
        }

        if (error.response.status === 403) {
          history.replace("/forbidden");
          return null;
        }

        if (error.response.status === 404) {
          if (error.response.config.url.startsWith("/")) {
            history.replace("/404");
            return null;
          }
        }

        // Organization is not selected or current Organization User is not active.
        if (error.response.status === 417) {
          history.replace("/forbidden");
          return null;
        }

        // Current organization does not have a valid subscription.
        if (error.response.status === 423) {
          history.push("/account/billing");
          return null;
        }

        return Promise.reject(error);
      }
    );
  }, [history]);

  useEffect(() => {
    dispatch(updateUserContext());
  }, [dispatch]);

  return (
    <>
      <Helmet titleTemplate={`%s - ${t("Mapovate")}`}>
        <html lang={i18n.language} dir={i18n.language === "ar" ? "rtl" : "ltr"} />
        <title>Home</title>
      </Helmet>
      {isLoading && <Loading />}
      {!isLoading && (
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/verify-email/:userId/:token" component={VerifyEmailPage} />
          <Route path="/reset-password/:userId/:token" component={ResetPasswordPage} />
          <Route path="/accept-invitation/:inviteId/:token" component={AcceptInvitationPage} />
          <Route path="/forbidden" component={Forbidden} />
          <Route path="/404" component={NotFound} />
          <PrivateRoute path="/" component={MainPage} />
        </Switch>
      )}
    </>
  );
};

App.displayName = "App";

const ReduxApp = () => {
  return (
    <React.Suspense fallback="Loading...">
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </React.Suspense>
  );
};

ReduxApp.displayName = "ReduxApp";

export default ReduxApp;
