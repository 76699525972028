import React from "react";

type Props = {
  message: string;
  type: "success" | "danger" | "warning" | "info";
};

function AlertMessage(props: Props) {
  if (props.message) {
    return <div className={`alert alert-${props.type}`}>{props.message}</div>;
  }
  return null;
}

AlertMessage.defaultProps = {
  type: "danger",
};

export default AlertMessage;
