import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";

import AppState from "src/AppState";
import ErrorBoundary from "src/components/ErrorBoundary";
import PrivateRoute from "src/components/PrivateRoute";
import lazyComponent from "src/components/lazyComponent";
import Footer from "src/features/app/Footer";
import SiteHeader from "src/features/app/SiteHeader";
import { getOrganizations } from "src/features/organizations/actions";
import { MyThunkDispatch } from "src/features/organizations/types";
import { OrganizationUserRole, UserType } from "src/models";

const OrganizationsContainer = lazyComponent(() => import("src/features/organizations/OrganizationsContainer"));
const JourneyMapContainer = lazyComponent(() => import("src/features/journey-map/JourneyMapContainer"));
// const DefaultAction = lazyComponent(() => import("src/features/journey-map/DefaultAction"));

const JourneyMapDetailsContainer = lazyComponent(
  () => import("src/features/journey-map-details/JourneyMapDetailsContainer")
);
const EditorContainer = lazyComponent(() => import("src/features/editor/EditorContainer"));
const PersonaContainer = lazyComponent(() => import("src/features/persona/PersonaContainer"));
const MyProfileContainer = lazyComponent(() => import("src/features/my-profile/MyProfileContainer"));
const AccountContainer = lazyComponent(() => import("src/features/account/AccountContainer"));
const UsersContainer = lazyComponent(() => import("src/features/users/UsersContainer"));
const AdminOrganizationsContainer = lazyComponent(
  () => import("src/features-admin/organizations/OrganizationsContainer")
);

export default function MainPage() {
  const dispatch = useDispatch<MyThunkDispatch>();
  const currentUserType = useSelector<AppState, UserType>(
    (state) => state.authState.userContext.currentUser?.userType || UserType.Unknown
  );

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  const homePageComponent =
    currentUserType === UserType.Administrator ? () => <Redirect to="/admin/organizations" /> : OrganizationsContainer;

  return (
    <>
      <SiteHeader />
      <div id="main-content">
        <ErrorBoundary>
          <Switch>
            <Route path="/" exact component={homePageComponent} />
            <Route path="/profile" component={MyProfileContainer} />
            {/* <PrivateRoute requireCompanyUser path="/DefaultAction" component={DefaultAction} /> */}

            <PrivateRoute requireCompanyUser path="/journey-maps" component={JourneyMapContainer} />
            <PrivateRoute requireCompanyUser path="/journey-map/:id/editor" component={EditorContainer} />
            <PrivateRoute requireCompanyUser path="/journey-map/:id/details" component={JourneyMapDetailsContainer} />
            <PrivateRoute
              allowedRoles={[OrganizationUserRole.Owner, OrganizationUserRole.Manager]}
              path="/personas"
              component={PersonaContainer}
            />
            <PrivateRoute
              allowedRoles={[OrganizationUserRole.Owner, OrganizationUserRole.Manager]}
              path="/users"
              component={UsersContainer}
            />
            <PrivateRoute path="/account" component={AccountContainer} />

            <PrivateRoute
              allowedUserType={UserType.Administrator}
              path="/admin/organizations"
              component={AdminOrganizationsContainer}
            />
          </Switch>
        </ErrorBoundary>
      </div>
      <Footer />
    </>
  );
}
