// http://nicolasgallagher.com/redux-modules-and-code-splitting/
// https://dev.to/websavi/how-to-code-split-redux-store-to-further-improve-your-apps-performance-4gg9
// https://www.matthewgerstman.com/tech/redux-code-split-typecheck/

import { ReducerMap } from "src/AppState";

type ChangeListener = (reducers: ReducerMap) => void;

export class ReducerRegistry {
  private emitChange: ChangeListener | null;

  private reducers: ReducerMap;

  constructor() {
    this.emitChange = null;
    this.reducers = {};
  }

  getReducers() {
    return { ...this.reducers };
  }

  register(reducers: ReducerMap) {
    if (process.env.NODE_ENV !== "production") {
      Object.keys(reducers).forEach((key) => {
        // eslint-disable-next-line no-console
        console.info(
          `%cRegistering Reducer: ${key}`,
          "color: white; background-color: blue; font-weight: bold; padding-left: 5px; padding-right: 5px;"
        );
      });
    }

    this.reducers = { ...this.reducers, ...reducers };

    if (this.emitChange) {
      this.emitChange(this.getReducers());
    }
  }

  setChangeListener(listener: ChangeListener) {
    this.emitChange = listener;
  }
}

const reducerRegistry = new ReducerRegistry();
export default reducerRegistry;
