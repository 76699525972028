import Axios from "axios";
import { BroadcastChannel } from "broadcast-channel";

import { MyThunkResult } from "src/features/organizations/types";
import { formatApiError } from "src/helpers";
import { Organization } from "src/models";

const broadcastChannel = new BroadcastChannel("currentOrganizationChange");

broadcastChannel.onmessage = () => {
  window.location.assign("/");
};

export const getOrganizations = (): MyThunkResult<Organization[]> => {
  return async (dispatch) => {
    dispatch({ type: "GET_ORGANIZATIONS_REQUEST", request: null });

    try {
      const response = await Axios.get<Organization[]>("/api/organizations");
      dispatch({
        request: null,
        response: response.data,
        type: "GET_ORGANIZATIONS_SUCCESS",
      });
      return Promise.resolve(response.data);
    } catch (error) {
      const formattedError = formatApiError(error);
      dispatch({ type: "GET_ORGANIZATIONS_ERROR", request: null, error: formattedError });
      return Promise.reject(formattedError);
    }
  };
};

export const setOrganization = (id: number): MyThunkResult<null> => {
  return async (dispatch) => {
    dispatch({ type: "SET_ORGANIZATION_REQUEST", request: id });

    try {
      await Axios.post(`api/set-organization/${id}`);
      dispatch({
        request: id,
        response: null,
        type: "SET_ORGANIZATION_SUCCESS",
      });
      broadcastChannel.postMessage(id);
      return Promise.resolve(null);
    } catch (error) {
      const formattedError = formatApiError(error);
      dispatch({ type: "SET_ORGANIZATION_ERROR", request: id, error: formattedError });
      return Promise.reject(formattedError);
    }
  };
};
