import React, { ComponentType } from "react";

import Loading from "src/components/Loading";

export default function lazyComponent<T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) {
  const Component = React.lazy(factory);

  return function WrappedLazyComponent(props: any) {
    return (
      <React.Suspense fallback={<Loading />}>
        <Component {...props} />
      </React.Suspense>
    );
  };
}
