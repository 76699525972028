/* eslint-disable no-param-reassign */

import produce from "immer";

import { AuthActions, AuthState, UserContext } from "src/features/auth/types";

const initialState: AuthState = {
  isLoading: true,
  userContext: {
    isLoggedIn: false,
  },
};

export default (state: AuthState = initialState, action: AuthActions) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "UPDATE_USER_CONTEXT_REQUEST": {
        draft.isLoading = true;
        return draft;
      }

      case "UPDATE_USER_CONTEXT_RESPONSE": {
        draft.isLoading = false;
        draft.userContext = action.response;
        return draft;
      }

      case "LOGIN_SUCCESS": {
        draft.userContext = action.response;
        return draft;
      }

      case "LOGIN_ERROR": {
        const userContext: UserContext = {
          isLoggedIn: false,
        };
        draft.userContext = userContext;
        return draft;
      }

      default: {
        return draft;
      }
    }
  });
};
