import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import AppState from "src/AppState";
import HtmlPhrase from "src/components/HtmlPhrase";
import { logout } from "src/features/auth/actions";
import { InviteResponse, MyThunkDispatch } from "src/features/auth/types";

type Props = {
  inviteToken: string;
} & InviteResponse;

export default function AcceptInviteLogoutForm(props: Props) {
  const { t } = useTranslation();
  const currentEmailAddress = useSelector<AppState, string>(
    (state) => state.authState.userContext.currentUser?.emailAddress || ""
  );
  const dispatch = useDispatch<MyThunkDispatch>();

  function handleClick() {
    dispatch(logout()).then(() => {
      window.location.reload();
    });
  }

  return (
    <>
      <h4 className="mb-4">{t("JoinOrganization")}</h4>
      <p className="alert alert-info">
        <HtmlPhrase
          phrase={t("JoinOrganization_LogoutFirstMessage", {
            inviteEmail: props.invite.emailAddress,
            userEmail: currentEmailAddress,
            name: props.organization.name,
          })}
        />
      </p>
      <div>
        <button type="button" className="btn btn-primary" onClick={handleClick}>
          {t("LogOut")}
        </button>
      </div>
    </>
  );
}
