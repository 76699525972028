import { Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";

import AlertMessage from "src/components/AlertMessage";
import SubmitButton from "src/components/SubmitButton";
import TextField from "src/components/TextField";
import { forgotPassword } from "src/features/auth/actions";
import { ForgotPasswordRequest, MyDispatchProp } from "src/features/auth/types";
import { ErrorInfo, handleModelStateErrors } from "src/helpers";
import { USER_EMAIL_ADDRESS_MAX_LENGTH } from "src/models";

type Props = {
  onSuccess(emailAddress: string): void;
} & RouteComponentProps &
  MyDispatchProp;

const ForgotPasswordForm = (props: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const initialValues: ForgotPasswordRequest = {
    emailAddress: "",
  };

  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string()
      .trim()
      .email(t("Validation_Email_Invalid"))
      .required(t("Validation_Email_Required"))
      .max(USER_EMAIL_ADDRESS_MAX_LENGTH, t("Validation_EmailAddress_TooLong", { max: USER_EMAIL_ADDRESS_MAX_LENGTH })),
  });

  const handleSubmit = (values: ForgotPasswordRequest, helpers: FormikHelpers<ForgotPasswordRequest>) => {
    helpers.setSubmitting(true);
    props
      .dispatch(forgotPassword(values))
      .then(() => {
        props.onSuccess(values.emailAddress);
      })
      .catch((err: ErrorInfo) => {
        helpers.setSubmitting(false);
        if (!handleModelStateErrors(err, helpers.setErrors, setError)) {
          setError(t("UnknownError"));
        }
      });
  };

  return (
    <>
      <AlertMessage message={error} />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, isValid }) => (
          <Form>
            <TextField name="emailAddress" type="email" placeholder={t("EmailAddress")} autoComplete="email" />
            <SubmitButton
              text={t("SendInstructions")}
              className="btn-lg btn-block mt-2"
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

ForgotPasswordForm.displayName = "ForgotPasswordForm";

export default connect()(ForgotPasswordForm);
