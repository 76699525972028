import { useField } from "formik";
import React from "react";

type Props = {
  name: string;
  children?: React.ReactNode;
  id?: string;
  helpText?: string | React.ReactNode;
  excludeDecoration?: boolean;
  omitHtmlFor?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const CheckBoxField = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const [field, meta] = useField<string>({ ...props, type: "checkbox" });
  const { name, id, helpText, excludeDecoration, omitHtmlFor, children, ...rest } = props;

  let className = "form-check-input";

  if (meta.touched) {
    if (meta.error) {
      className += " is-invalid";
    } else {
      className += " is-valid";
    }
  }

  const controlMarkup = (
    <div className="form-check">
      <input id={id || name} type="checkbox" ref={ref} className={className} {...field} {...rest} />
      {children && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className="form-check-label" htmlFor={omitHtmlFor ? undefined : id || name}>
          {children}
        </label>
      )}
      {helpText && <div className="form-text text-muted">{helpText}</div>}
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </div>
  );

  if (excludeDecoration) {
    return controlMarkup;
  }

  return <div className="form-group">{controlMarkup}</div>;
});

CheckBoxField.defaultProps = {
  excludeDecoration: false,
  omitHtmlFor: false,
};

CheckBoxField.displayName = "CheckBoxField";

export default CheckBoxField;
