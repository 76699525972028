import React from "react";

function textToHslColor(text: string, saturation: number, lightness: number) {
  let hash = 0;
  for (let i = 0; i < text.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hue = hash % 360;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

type Props = {
  size: number;
  text?: string;
  image?: string;
  className?: string;
  minWidth?:string;
  ref?:any;
  id?:string;
  sizeStr?:string;

};

export default function Avatar(props: Props) {
  if (props.image) {
    return (
      <div
        className={props.className}
        style={{
          width: props.size,
          height: props.size,
          backgroundPosition: "50% 50%",
          backgroundSize:props.sizeStr?? "cover",
          backgroundImage: `url("${props.image}")`,
          backgroundRepeat: "no-repeat",
          display: "inline-block",
          verticalAlign: "middle",
          minWidth: props.minWidth??"auto"

        }}

        ref={props.ref}

        id={props.id}
      />
    );
  }

  const name = props.text ? props.text.trim() : "";
  let adjustedSize = props.size;

  const nameSplit = String(name).toUpperCase().split(" ");

  let initials;
  let dataURI;

  if (nameSplit.length === 1) {
    initials = nameSplit[0] ? nameSplit[0].charAt(0) : "?";
  } else {
    initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
  }

  if (window.devicePixelRatio) {
    adjustedSize *= window.devicePixelRatio;
  }

  let canvas: HTMLCanvasElement | null = document.createElement("canvas");
  canvas.width = adjustedSize;
  canvas.height = adjustedSize;

  const context = canvas.getContext("2d");
  if (context) {
    context.fillStyle = textToHslColor(name, 40, 50);
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = `${Math.round(canvas.width / 2)}px sans-serif`;
    context.textAlign = "center";
    context.fillStyle = "#fff";
    context.fillText(initials, adjustedSize / 2, adjustedSize / 1.5);

    dataURI = canvas.toDataURL();
  }

  canvas = null;

  return <img src={dataURI} width={props.size} height={props.size} alt={props.text} className={props.className} />;
}
