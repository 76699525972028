import React, { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const mapovateLogo = require("src/features/app/mapovate-logo.png");

interface Props {
  children: ReactNode;
  footer?: ReactNode;
  isWide: boolean;
}

const SplashLayout = (props: Props) => {
  useEffect(() => {
    document.body.id = "splash-screen";

    return () => {
      document.body.id = "";
    };
  });

  const { t } = useTranslation();

  return (
    <div className="splash-container">
      <div className="splash-frame">
        <div className="logo-block">
          <Link to="/">
            <img src={mapovateLogo} height={50} alt={t("Mapovate")} />
          </Link>
        </div>
        <div className="body-frame">{props.children}</div>
        <div className="footer-frame">{props.footer}</div>
      </div>
    </div>
  );
};

SplashLayout.defaultProps = {
  isWide: false,
};

SplashLayout.displayName = "SplashLayout";

export default SplashLayout;
