import { applyMiddleware, combineReducers, compose, createStore, ReducersMapObject } from "redux";
import reduxThunk from "redux-thunk";

import AppState, { ReducerMap } from "src/AppState";
import authReducer from "src/features/auth/reducer";
import organizationReducer from "src/features/organizations/reducer";
import reducerRegistry from "src/reducerRegistry";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSIONS_COMPOSE__?: any;
  }
}

const initialState: Partial<AppState> = {}; // Can be retrieved from local storage or server.

const combineLazyReducers = (reducers: ReducerMap) => {
  return combineReducers(reducers as ReducersMapObject<AppState>);
};

// Register all the default reducers here.
reducerRegistry.register({ authState: authReducer });
reducerRegistry.register({ organizationsState: organizationReducer });

const reducers = combineLazyReducers(reducerRegistry.getReducers());
// eslint-disable-next-line no-underscore-dangle
const enhancers = window.__REDUX_DEVTOOLS_EXTENSIONS_COMPOSE__ || compose;
const store = createStore(reducers, initialState, enhancers(applyMiddleware(reduxThunk)));

reducerRegistry.setChangeListener((x) => {
  store.replaceReducer(combineLazyReducers(x));
});

export default store;
