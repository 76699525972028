import Axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AlertMessage from "src/components/AlertMessage";
import HtmlPhrase from "src/components/HtmlPhrase";
import { InviteResponse } from "src/features/auth/types";
import { ErrorInfo } from "src/helpers";

type Props = {
  inviteToken: string;
} & InviteResponse;

export default function AcceptInviteForm(props: Props) {
  const { t } = useTranslation();
  const [error, setError] = useState("");

  function handleClick() {
    Axios.post(`/api/invitation/${props.invite.id}/${props.inviteToken}/accept`)
      .then(() => {
        window.location.assign("/");
      })
      .catch((apiError: ErrorInfo) => {
        setError(apiError.statusText);
      });
  }

  return (
    <>
      <h4 className="mb-4">{t("JoinOrganization")}</h4>
      <p className="alert alert-info">
        <HtmlPhrase phrase={t("JoinOrganization_AcceptInviteMessage", { name: props.organization.name })} />
      </p>
      <AlertMessage message={error} />
      <div>
        <button type="button" className="btn btn-primary" onClick={handleClick}>
          {t("AcceptInvitation")}
        </button>
      </div>
    </>
  );
}
