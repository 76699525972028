import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";

import ErrorBoundary from "src/components/ErrorBoundary";
import HtmlPhrase from "src/components/HtmlPhrase";
import SplashLayout from "src/features/app/SplashLayout";
import RegisterForm from "src/features/auth/RegisterForm";

type Props = RouteComponentProps;

const RegisterPage = (props: Props) => {
  const { t } = useTranslation();
  const [registeredEmail, setRegisteredEmail] = useState("");

  const footerBlock = (
    <div className="text-center mt-4">
      <Link to="/login">{t("AlreadyHaveAccount")}</Link>
    </div>
  );

  const handleRegistrationSuccess = (emailAddress: string) => {
    setRegisteredEmail(emailAddress);
  };

  if (registeredEmail) {
    return (
      <SplashLayout>
        <h3 className="text-center">{t("SignUpSuccess_Title")}</h3>
        <p className="text-center lead">
          <HtmlPhrase phrase={t("SignUpSuccess_Message", { emailAddress: registeredEmail })} />
        </p>
      </SplashLayout>
    );
  }

  return (
    <SplashLayout footer={footerBlock}>
      <h3 className="mb-4 text-center">{t("CreateMapovateAccount")}</h3>
      <ErrorBoundary>
        <RegisterForm onRegisterSuccess={handleRegistrationSuccess} {...props} />
      </ErrorBoundary>
    </SplashLayout>
  );
};

RegisterPage.displayName = "RegisterPage";

export default RegisterPage;
