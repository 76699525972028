import React from "react";
import { useTranslation } from "react-i18next";

import HtmlPhrase from "src/components/HtmlPhrase";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer fixed-bottom">
      <div className="copyright-notice">
        <HtmlPhrase phrase={t("FooterCopyrightNotice", { year: new Date().getFullYear() })} />
      </div>
      <div className="footer-links">
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="https://www.mapovate.com/privacy-policy/">{t("PrivacyPolicy")}</a>
          </li>
          <li className="list-inline-item">
            <a href="https://www.mapovate.com/terms-of-service/">{t("TermsOfService")}</a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
