import React from "react";

type Props = {
  isSubmitting: boolean;
  isValid: boolean;
  text: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

function SubmitButton(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isSubmitting, isValid, text, className, type, disabled, ...rest } = props;

  const classNames = `btn btn-primary ${className}`;

  return (
    <button type="submit" className={classNames} disabled={isSubmitting || !isValid} {...rest}>
      {isSubmitting && (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />{" "}
        </>
      )}
      {text}
    </button>
  );
}

export default SubmitButton;
