import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";

import AppState from "src/AppState";
import { UserContext } from "src/features/auth/types";
import { UserType, OrganizationUserRole } from "src/models";

interface Props extends RouteProps {
  allowedUserType?: UserType;
  allowedRoles: OrganizationUserRole[];
  requireCompanyUser: boolean;
}

const redirectToLoginRoute = () => <Redirect to="/login" />;
const redirectToForbiddenRoute = () => <Redirect to="/forbidden" />;

function PrivateRoute(props: Props) {
  const { component, allowedUserType, allowedRoles, requireCompanyUser, ...rest } = props;
  const userContext = useSelector<AppState, UserContext>((state) => state.authState.userContext);

  if (!userContext.isLoggedIn) {
    return <Route render={redirectToLoginRoute} {...rest} />;
  }

  let isAllowed = false;

  if (allowedUserType) {
    isAllowed = userContext.currentUser ? userContext.currentUser.userType === allowedUserType : false;
  } else {
    isAllowed = true;
  }

  if (isAllowed) {
    if (requireCompanyUser) {
      isAllowed = !!(userContext.currentUser && userContext.currentOrganizationUser);
    } else if (allowedRoles.length > 0) {
      isAllowed = !!(
        userContext.currentUser &&
        userContext.currentOrganizationUser &&
        allowedRoles.indexOf(userContext.currentOrganizationUser.userRole) > -1
      );
    }
  }

  if (isAllowed) {
    return <Route component={component} {...rest} />;
  }

  return <Route render={redirectToForbiddenRoute} {...rest} />;
}

PrivateRoute.defaultProps = {
  requireCompanyUser: false,
  allowedRoles: [],
};

export default PrivateRoute;
