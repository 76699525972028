import { useTranslation } from "react-i18next";
import { enGB, arSA, ja } from "date-fns/locale"

type Language = {
  name: string,
  id: "en" | "ar" | "ja"
};

const defaultLanguage: Language = {
  name: "English",
  id: "en"
}

const availableLanguages: Language[] =
  [{
    name: "العربية",
    id: "ar"
  },
  {
    name: "日本",
    id: "ja"
  },
  {
    name: "English",
    id: "en"
  }];

const locales = {
  en: enGB,
  ar: arSA,
  ja,
}

const useLanguage = () => {
  const { i18n } = useTranslation();

  const currentLanguage: Language = availableLanguages.find((ln) => ln.id === i18n.language) || defaultLanguage;
  const otherLanguageOptions = availableLanguages.filter((language) => language !== currentLanguage);

  let currentLocale = locales[currentLanguage.id];

  return {
    currentLanguage, otherLanguageOptions, currentLocale
  }

}

export default useLanguage;
