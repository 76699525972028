import React from "react";
import { useTranslation } from "react-i18next";

import SplashLayout from "src/features/app/SplashLayout";

export default function Forbidden() {
  const { t } = useTranslation();

  return (
    <SplashLayout>
      <h2>{t("ForbiddenAction_Title")}</h2>
      <p>{t("ForbiddenAction_Message")}</p>
    </SplashLayout>
  );
}
