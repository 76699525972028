import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

import { getCookieValue } from "src/helpers";

type locales = "en" | "ja";
const langs: locales[] = ["en", "ja"];

i18next
  // load translation using xhr
  // https://github.com/i18next/i18next-xhr-backend
  .use(XHR)
  // detect user language
  // https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: "/api/locales/{{ns}}/{{lng}}",
    },
    debug: process.env.NODE_ENV !== "production",
    defaultNS: "ClientPhrases",
    fallbackLng: langs,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: "ClientPhrases",
    supportedLngs: ["en", "ar", "ja"],
  });

const currentLanguage = getCookieValue("mapovate_culture");

if (currentLanguage) {
  i18next.changeLanguage(currentLanguage);
}

export default i18next;
