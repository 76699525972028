import { ThunkAction, ThunkDispatch } from "redux-thunk";

// eslint-disable-next-line import/no-cycle
import AppState from "src/AppState";
import { ApiError, ApiRequest, ApiResponse } from "src/helpers";
import { User, Organization, OrganizationUser, OrganizationUserInvite, Subscription } from "src/models";

export interface UserContext {
  isLoggedIn: boolean;
  currentUser?: User;
  currentOrganizationUser?: OrganizationUser;
  currentOrganization?: Organization;
  currentSubscription?: Subscription;
}

export interface AuthState {
  isLoading: boolean;
  userContext: UserContext;
}

export interface LoginRequest {
  emailAddress: string;
  password: string;
  rememberMe: boolean;
}

export interface RegisterRequest {
  agreeToTerms: boolean;
  confirmPassword: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  password: string;
}

export interface ForgotPasswordRequest {
  emailAddress: string;
}

export interface VerifyResetPasswordTokenRequest {
  userId: number;
  token: string;
}

export interface ResetPasswordRequest {
  userId: number;
  token: string;
  password: string;
  confirmPassword: string;
}

export interface VerifyEmailRequest {
  userId: number;
  token: string;
  resetPassword: boolean;
}

export interface VerifyEmailResponse {
  resetToken: string;
}

export enum InviteAction {
  Accept = "Accept",
  Login = "Login",
  Register = "Register",
  Logout = "Logout",
}

export type InviteResponse = {
  action: InviteAction;
  invite: OrganizationUserInvite;
  organization: Organization;
};

export type AuthActions =
  | ({ type: "UPDATE_USER_CONTEXT_REQUEST" } & ApiRequest<null>)
  | ({ type: "UPDATE_USER_CONTEXT_RESPONSE" } & ApiRequest<null> & ApiResponse<UserContext>)
  | ({ type: "LOGIN_REQUEST" } & ApiRequest<LoginRequest>)
  | ({ type: "LOGIN_SUCCESS" } & ApiRequest<LoginRequest> & ApiResponse<UserContext>)
  | ({ type: "LOGIN_ERROR" } & ApiRequest<LoginRequest> & ApiError)
  | ({ type: "LOGOUT_REQUEST" } & ApiRequest<null>)
  | ({ type: "LOGOUT_SUCCESS" } & ApiRequest<null> & ApiResponse<null>)
  | ({ type: "REGISTER_REQUEST" } & ApiRequest<RegisterRequest>)
  | ({ type: "REGISTER_SUCCESS" } & ApiRequest<RegisterRequest> & ApiResponse<null>)
  | ({ type: "REGISTER_ERROR" } & ApiRequest<RegisterRequest> & ApiError)
  | ({ type: "FORGOT_PASSWORD_REQUEST" } & ApiRequest<ForgotPasswordRequest>)
  | ({ type: "FORGOT_PASSWORD_SUCCESS" } & ApiRequest<ForgotPasswordRequest> & ApiResponse<null>)
  | ({ type: "VERIFY_RESET_PASSWORD_TOKEN_REQUEST" } & ApiRequest<VerifyResetPasswordTokenRequest>)
  | ({ type: "VERIFY_RESET_PASSWORD_TOKEN_SUCCESS" } & ApiRequest<VerifyResetPasswordTokenRequest> & ApiResponse<null>)
  | ({ type: "VERIFY_RESET_PASSWORD_TOKEN_ERROR" } & ApiRequest<VerifyResetPasswordTokenRequest> & ApiError)
  | ({ type: "RESET_PASSWORD_REQUEST" } & ApiRequest<ResetPasswordRequest>)
  | ({ type: "RESET_PASSWORD_SUCCESS" } & ApiRequest<ResetPasswordRequest> & ApiResponse<null>)
  | ({ type: "RESET_PASSWORD_ERROR" } & ApiRequest<ResetPasswordRequest> & ApiError)
  | ({ type: "VERIFY_EMAIL_REQUEST" } & ApiRequest<VerifyEmailRequest>)
  | ({ type: "VERIFY_EMAIL_SUCCESS" } & ApiRequest<VerifyEmailRequest> & ApiResponse<VerifyEmailResponse>)
  | ({ type: "VERIFY_EMAIL_ERROR" } & ApiRequest<VerifyEmailRequest> & ApiError)
  | ({ type: "UPDATE_USER_Orgnization_Image" } & ApiResponse<string>);

export type MyThunkResult<R> = ThunkAction<Promise<R>, AppState, unknown, AuthActions>;
export type MyThunkDispatch = ThunkDispatch<AppState, unknown, AuthActions>;

export type MyDispatchProp = {
  dispatch: MyThunkDispatch;
};
