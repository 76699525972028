import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";

import AlertMessage from "src/components/AlertMessage";
import SubmitButton from "src/components/SubmitButton";
import TextField from "src/components/TextField";
import { login } from "src/features/auth/actions";
import { LoginRequest, MyThunkDispatch, UserContext } from "src/features/auth/types";
import { ErrorInfo, handleModelStateErrors } from "src/helpers";
import { USER_EMAIL_ADDRESS_MAX_LENGTH } from "src/models";

interface Props extends RouteComponentProps {
  loginAction(request: LoginRequest): Promise<UserContext>;
}

const LoginForm = (props: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const emailRef = useRef<HTMLInputElement>(null);
  const initialValues: LoginRequest = { emailAddress: "", password: "", rememberMe: true };

  useEffect(() => {
    if (emailRef !== null && emailRef.current !== null) {
      emailRef.current.focus();
    }
  });

  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string()
      .trim()
      .email(t("Validation_Email_Invalid"))
      .required(t("Validation_Email_Required"))
      .max(USER_EMAIL_ADDRESS_MAX_LENGTH, t("Validation_EmailAddress_TooLong", { max: USER_EMAIL_ADDRESS_MAX_LENGTH })),
    password: Yup.string().required(t("Validation_Password_Required")).min(8, t("Validation_Password_Min8")),
  });

  const handleSubmit = (values: LoginRequest, helpers: FormikHelpers<LoginRequest>) => {
    helpers.setSubmitting(true);
    setError("");
    props
      .loginAction(values)
      .then(() => {
        props.history.push("/");
      })
      .catch((err: ErrorInfo) => {
        helpers.setSubmitting(false);
        if (!handleModelStateErrors(err, helpers.setErrors, setError)) {
          setError(t("UnknownError"));
        }
      });
  };

  return (
    <>
      <AlertMessage message={error} />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, isValid }) => (
          <Form>
            <TextField
              name="emailAddress"
              type="email"
              placeholder={t("EmailAddress")}
              autoComplete="email"
              ref={emailRef}
            />
            <TextField name="password" type="password" placeholder={t("Password")} autoComplete="current-password" />
            <SubmitButton
              id="submit"
              text={t("Login")}
              className="btn-lg btn-block mt-2"
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  loginAction: (request: LoginRequest) => dispatch(login(request)),
});

LoginForm.displayName = "LoginForm";

export default connect(null, mapDispatchToProps)(LoginForm);
