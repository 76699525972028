import { FormikErrors } from "formik";
import { Dispatch, SetStateAction } from "react";

import { Subscription, BillingCycle, OrganizationUserRole } from "src/models";

export type ErrorInfo = {
  status: number;
  statusText: string;
  data: any; // tslint:disable-line:no-any
};

export type ApiRequest<T> = { request: T };
export type ApiResponse<T> = { response: T };
export type ApiError = { error: ErrorInfo };

export function formatApiError(error: any): ErrorInfo {
  if (error.response) {
    return {
      data: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    };
  }

  return {
    data: "",
    status: 0,
    statusText: "Unknown Error",
  };
}

export function handleModelStateErrors<T>(
  error: ErrorInfo,
  setFormErrors: (errors: FormikErrors<T>) => void,
  setGlobalError: Dispatch<SetStateAction<string>>
): boolean {
  if (error && error.data && error.data.validationErrors) {
    setFormErrors(error.data.validationErrors);
    if (error.data.validationErrors[""]) {
      setGlobalError(error.data.validationErrors[""]);
    }

    return true;
  }

  return false;
}

export function arrayMove<T>(array: T[], fromPosition: number, toPosition: number) {
  let oldIndex = fromPosition;
  let newIndex = toPosition;

  while (oldIndex < 0) {
    oldIndex += array.length;
  }

  while (newIndex < 0) {
    newIndex += array.length;
  }
  if (newIndex >= array.length) {
    let k = newIndex - array.length;
    // eslint-disable-next-line no-plusplus
    while (k-- + 1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      array.push(undefined);
    }
  }

  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  return array;
}

export function objectToFormData(entity: any) {
  const formData = new FormData();

  function appendFormData(data: any, root = "") {
    // eslint-disable-next-line no-param-reassign
    root = root || "";
    if (data instanceof File) {
      formData.append(root, data);
    } else if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i += 1) {
        appendFormData(data[i], `${root}[${i}]`);
      }
    } else if (typeof data === "object" && data) {
      Object.keys(data).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          if (root === "") {
            appendFormData(data[key], key);
          } else {
            appendFormData(data[key], `${root}.${key}`);
          }
        }
      });
    } else if (data !== null && typeof data !== "undefined") {
      formData.append(root, data);
    }
  }

  appendFormData(entity);

  return formData;
}

let negativeId = 0;

export function getNegativeId() {
  negativeId -= 1;
  return negativeId;
}

export const DEFAULT_JOURNEY_MAP_BOX_BG_COLOR = "#ECF0F4";

export function getContrastColor(hexColor: string) {
  let color = hexColor;

  // If a leading # is provided, remove it
  if (color.slice(0, 1) === "#") {
    color = color.slice(1);
  }

  // If a three-character hex-code, make six-character
  if (color.length === 3) {
    color = color
      .split("")
      .map((hex) => hex + hex)
      .join("");
  }

  // Convert to RGB value
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  // Get YIQ ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? "black" : "white";
}

export function getFormattedProducePrice(subscription: Subscription) {
  let price: number;

  switch (subscription.billingCycle) {
    case BillingCycle.Monthly: {
      price = subscription.productPrice.monthPrice;
      break;
    }

    case BillingCycle.Yearly: {
      price = subscription.productPrice.yearPrice;
      break;
    }

    default: {
      price = 0;
      break;
    }
  }

  return subscription.currency.symbol + price;
}

export function hasManagerAccess(role: OrganizationUserRole) {
  return role === OrganizationUserRole.Owner || role === OrganizationUserRole.Manager;
}

export function getCookieValue(cookieName: string) {
  const b = document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : "";
}
