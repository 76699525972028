import Axios from "axios";
import { Formik, Form, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import AlertMessage from "src/components/AlertMessage";
import CheckBoxField from "src/components/CheckBoxField";
import HtmlPhrase from "src/components/HtmlPhrase";
import SubmitButton from "src/components/SubmitButton";
import TextField from "src/components/TextField";
import { InviteResponse } from "src/features/auth/types";
import { ErrorInfo, handleModelStateErrors } from "src/helpers";
import { USER_EMAIL_ADDRESS_MAX_LENGTH, USER_FIRST_NAME_MAX_LENGTH, USER_LAST_NAME_MAX_LENGTH } from "src/models";

type InviteRegisterRequest = {
  agreeToTerms: boolean;
  confirmPassword: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  password: string;
};

type Props = {
  inviteToken: string;
} & InviteResponse;

export default function AcceptInviteRegisterForm(props: Props) {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const history = useHistory();

  const initialValues: InviteRegisterRequest = {
    agreeToTerms: false,
    confirmPassword: "",
    emailAddress: props.invite.emailAddress,
    firstName: "",
    lastName: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    agreeToTerms: Yup.boolean().oneOf([true], t("Validation_AgreeToTerms_Required")),
    confirmPassword: Yup.string()
      .required(t("Validation_ConfirmPassword_Required"))
      .oneOf([Yup.ref("password")], t("Validation_ConfirmPassword_Mismatch")),
    emailAddress: Yup.string()
      .trim()
      .email(t("Validation_Email_Invalid"))
      .required(t("Validation_Email_Required"))
      .max(USER_EMAIL_ADDRESS_MAX_LENGTH, t("Validation_EmailAddress_TooLong", { max: USER_EMAIL_ADDRESS_MAX_LENGTH })),
    firstName: Yup.string()
      .trim()
      .required(t("Validation_FirstName_Required"))
      .max(USER_FIRST_NAME_MAX_LENGTH, t("Validation_FirstName_TooLong", { max: USER_FIRST_NAME_MAX_LENGTH })),
    lastName: Yup.string()
      .trim()
      .required(t("Validation_LastName_Required"))
      .max(USER_LAST_NAME_MAX_LENGTH, t("Validation_LastName_TooLong", { max: USER_LAST_NAME_MAX_LENGTH })),
    password: Yup.string().required(t("Validation_Password_Required")).min(8, t("Validation_Password_Min8")),
  });

  const handleSubmit = (values: InviteRegisterRequest, helpers: FormikHelpers<InviteRegisterRequest>) => {
    helpers.setSubmitting(true);
    setError("");

    Axios.post(`/api/invitation/${props.invite.id}/${props.inviteToken}/register`, values)
      .then(() => {
        toast.success(t("InvitedAcceptedLoginToContinue"));
        history.push("/");
      })
      .catch((err: ErrorInfo) => {
        if (!handleModelStateErrors(err, helpers.setErrors, setError)) {
          setError(t("UnknownError"));
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  return (
    <div>
      <h4 className="mb-4">{t("JoinOrganization")}</h4>
      <p className="alert alert-info">
        <HtmlPhrase phrase={t("JoinOrganization_RegisterMessage", { name: props.organization.name })} />
      </p>
      <AlertMessage message={error} />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, isValid }) => (
          <Form>
            <div className="row">
              <div className="col-12">
                <TextField
                  name="emailAddress"
                  type="email"
                  placeholder={t("EmailAddress")}
                  autoComplete="email"
                  disabled
                />
              </div>
              <div className="col-6">
                <TextField name="firstName" type="text" placeholder={t("FirstName")} autoComplete="given-name" />
              </div>
              <div className="col-6">
                <TextField name="lastName" type="text" placeholder={t("LastName")} autoComplete="family-name" />
              </div>
              <div className="col-6">
                <TextField name="password" type="password" placeholder={t("Password")} autoComplete="new-password" />
              </div>
              <div className="col-6">
                <TextField
                  name="confirmPassword"
                  type="password"
                  placeholder={t("RetypePassword")}
                  autoComplete="new-password"
                />
              </div>
              <div className="col-12">
                <CheckBoxField name="agreeToTerms" omitHtmlFor>
                  <HtmlPhrase phrase={t("PrivacyPolicyConsentMessage")} />
                </CheckBoxField>
              </div>
            </div>
            <SubmitButton
              text={t("CreateAccount")}
              className="btn-lg btn-block mt-2"
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
