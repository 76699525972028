import React from "react";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";

import ErrorBoundary from "src/components/ErrorBoundary";
import SplashLayout from "src/features/app/SplashLayout";
import LoginForm from "src/features/auth/LoginForm";

type Props = RouteComponentProps;

export default function LoginPage(props: Props) {
  const { t } = useTranslation();

  const footerBlock = (
    <div className="row mt-4">
      <div className="col-6">
        <Link to="/register">{t("NoAccountSignUp")}</Link>
      </div>
      <div className="col-6 text-right">
        <Link to="/forgot-password">{t("ForgotPassword")}</Link>
      </div>
    </div>
  );

  return (
    <SplashLayout footer={footerBlock}>
      <ErrorBoundary>
        <LoginForm {...props} />
      </ErrorBoundary>
    </SplashLayout>
  );
}
