/* eslint-disable react/no-danger */

import React from "react";

type Props = {
  phrase: string;
  component?: "div" | "span";
} & React.HTMLAttributes<HTMLSpanElement>;

const HtmlPhrase = (props: Props) => {
  const { phrase, component, ...rest } = props;

  if (component === "div") {
    return <div dangerouslySetInnerHTML={{ __html: phrase }} {...rest} />;
  }
  return <span dangerouslySetInnerHTML={{ __html: phrase }} {...rest} />;
};

HtmlPhrase.defaultProps = {
  component: "span",
};

HtmlPhrase.displayName = "HtmlPhrase";

export default HtmlPhrase;
