import Axios from "axios";
import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

import AlertMessage from "src/components/AlertMessage";
import ErrorBoundary from "src/components/ErrorBoundary";
import Loading from "src/components/Loading";
import SplashLayout from "src/features/app/SplashLayout";
import AcceptInviteForm from "src/features/auth/AcceptInviteForm";
import AcceptInviteLoginForm from "src/features/auth/AcceptInviteLoginForm";
import AcceptInviteLogoutForm from "src/features/auth/AcceptInviteLogoutForm";
import AcceptInviteRegisterForm from "src/features/auth/AcceptInviteRegisterForm";
import { InviteResponse, InviteAction } from "src/features/auth/types";
import { ErrorInfo } from "src/helpers";

type Props = RouteComponentProps<{ inviteId: string; token: string }>;

export default function AcceptInvitationPage(props: Props) {
  const inviteId = parseInt(props.match.params.inviteId, 10);
  const { token } = props.match.params;

  const [isLoading, setIsLoading] = useState(true);
  const [inviteResponse, setInviteResponse] = useState<InviteResponse>();
  const [error, setError] = useState("");

  useEffect(() => {
    setIsLoading(true);
    const cancelToken = Axios.CancelToken.source();

    Axios.get<InviteResponse>(`/api/invitation/${inviteId}/${token}`)
      .then((response) => {
        setInviteResponse(response.data);
      })
      .catch((apiError: ErrorInfo) => {
        setError(apiError.statusText);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      cancelToken.cancel();
    };
  }, [inviteId, token]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <SplashLayout>
        <AlertMessage message={error} />
      </SplashLayout>
    );
  }

  if (inviteResponse) {
    return (
      <SplashLayout>
        <ErrorBoundary>
          {inviteResponse.action === InviteAction.Accept && (
            <AcceptInviteForm inviteToken={token} {...inviteResponse} />
          )}
          {inviteResponse.action === InviteAction.Login && (
            <AcceptInviteLoginForm inviteToken={token} {...inviteResponse} />
          )}
          {inviteResponse.action === InviteAction.Register && (
            <AcceptInviteRegisterForm inviteToken={token} {...inviteResponse} />
          )}
          {inviteResponse.action === InviteAction.Logout && (
            <AcceptInviteLogoutForm inviteToken={token} {...inviteResponse} />
          )}
        </ErrorBoundary>
      </SplashLayout>
    );
  }

  return null;
}
