import React from "react";

type Props = {
  small: boolean;
};

const Loading = (props: Props) => {
  const radius = !props.small ? 20 : 10;
  const stroke = !props.small ? 5 : 3;
  const className = !props.small ? "loading-box" : "loading-box loading-no-padding";

  return (
    <div className={className}>
      <svg className="spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r={radius} fill="none" strokeWidth={stroke} />
      </svg>
    </div>
  );
};

Loading.defaultProps = {
  small: false,
};

Loading.displayName = "Loading";

export default Loading;
