import { FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import AlertMessage from "src/components/AlertMessage";
import SplashLayout from "src/features/app/SplashLayout";
import ResetPasswordForm from "src/features/auth/ResetPasswordForm";
import { resetPassword, verifyResetPasswordToken } from "src/features/auth/actions";
import { MyThunkDispatch, ResetPasswordRequest } from "src/features/auth/types";
import { ErrorInfo, handleModelStateErrors } from "src/helpers";

type RouteParams = {
  userId: string;
  token: string;
};

type Props = RouteComponentProps<RouteParams>;

const ResetPasswordPage = (props: Props) => {
  const userId = parseInt(props.match.params.userId, 10);
  const { token } = props.match.params;
  const dispatch = useDispatch<MyThunkDispatch>();

  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(true);

  useEffect(() => {
    dispatch(verifyResetPasswordToken({ token, userId }))
      .then(() => {
        setIsTokenValid(true);
      })
      .catch(() => {
        setIsTokenValid(false);
      });
  }, [userId, token, dispatch]);

  const handleSubmit = (values: ResetPasswordRequest, helpers: FormikHelpers<ResetPasswordRequest>) => {
    helpers.setSubmitting(true);
    setError("");
    dispatch(resetPassword(values))
      .then(() => {
        props.history.push("/login");
      })
      .catch((err: ErrorInfo) => {
        helpers.setSubmitting(false);
        if (!handleModelStateErrors(err, helpers.setErrors, setError)) {
          setError(t("UnknownError"));
        }
      });
  };

  return (
    <SplashLayout>
      <h4 className="mb-4 text-center">{t("ResetYourPassword")}</h4>
      {!isTokenValid && <AlertMessage message={t("PasswordResetTokenInvalid")} />}
      {isTokenValid && <ResetPasswordForm onSubmit={handleSubmit} error={error} userId={userId} token={token} />}
    </SplashLayout>
  );
};

ResetPasswordPage.displayName = "ResetPasswordPage";

export default ResetPasswordPage;
